import { library } from "@fortawesome/fontawesome-svg-core";
import { faLinkedinIn, faFacebookF, faYoutube } from "@fortawesome/free-brands-svg-icons";
import {
  faFileInvoice,
  faPhoneAlt,
  faChevronCircleUp,
  faArrowCircleLeft,
  faArrowCircleRight,
  faLink,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faTimesCircle, faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import FontAwesomeIcon from "./FontAwesomeIcon";

library.add(faLinkedinIn, faFacebookF, faYoutube);
library.add(faFileInvoice, faPhoneAlt, faChevronCircleUp, faArrowCircleLeft, faArrowCircleRight, faLink, faDownload);
library.add(faEnvelope, faTimesCircle, faCommentAlt);

export { FontAwesomeIcon };
