<template>
  <HomePage />
</template>

<script>
import HomePage from "./components/HomePage";

export default {
  name: "App",
  components: { HomePage }
};
</script>

<style lang="scss">
@import "./mixins/fonts.scss";

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  background: #0a0524 url("./assets/synthwave.jpg") center/cover fixed;
}
#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
