<template>
  <a name="about" class="home-page__anchor" />
  <h2 class="home-page__sub-header">About Me</h2>
  <div class="home-page__text-block">
    Computer engineer and friendly neighborhood cryptid based in southern New England. I enjoy gaming, video editing,
    hiking and world travel. I've worked in technology for over {{ yearsSince05 }} years.
  </div>

  <h2 class="home-page__sub-header">Skills</h2>
  <div class="home-page__text-block">
    <h3 class="home-page__inline-header">Core:</h3>
    HTML5, JavaScript
    <span title="Ecma has continued to update since, but ES6 is the last time it had a snappy abbreviation.">ES6</span>,
    CSS / CSS3 / SASS

    <h3 class="home-page__inline-header">Frameworks:</h3>
    React, Angular, Vue, Rails, Bootstrap, <span title="not *technically* a framework">WordPress</span>

    <h3 class="home-page__inline-header">Tools / Etc:</h3>
    Intuitive understanding of Git, GitHub, NPM, and agile CI/CD. Testing via Cypress, Jest, Jasmine, Enzyme, and
    Nightwatch. Also experienced in JQuery, PHP, MySQL, Java/Kotlin, C/C++.
  </div>

  <h2 class="home-page__sub-header">Education</h2>
  <div class="home-page__text-block home-page__text-block_edu">
    <img class="home-page__uri-logo" src="../assets/logos/uri_logo.png" alt="University of RI logo" />
    <div class="home-page__edu">
      <h3 class="home-page__inline-header home-page__inline-header_edu">
        University of
        <div class="home-page__edu-inline-block">Rhode Island</div>
      </h3>
      <em>B.S. in Computer Engineering</em>
      <div class="home-page__edu-inline-block">&nbsp; (GPA: 3.48 / 4.00)</div>
      <ul class="home-page__list">
        <li>Tau Beta Pi Honor Society</li>
        <li>
          Eta Kappa Nu Honor Society<br />
          <em>Student VP and Webmaster</em>
        </li>
      </ul>
      <div class="home-page__dates">2007 - 2011</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoBlocks",
  computed: {
    yearsSince05: () => new Date().getFullYear() - 2005
  }
};
</script>

<style lang="scss">
.home-page {
  @media (min-width: 1176px) {
    &__above-fold-right-col > &__sub-header:first-of-type {
      margin-top: 0;
    }
  }

  &__inline-header {
    &_edu {
      @media (max-width: 435px) {
        text-align: center;
      }
    }
  }

  &__text-block {
    &#{&}_edu {
      display: flex;
      font-size: 18px;

      @media (max-width: 435px) {
        flex-direction: column;
        align-items: center;
      }
    }
    span[title] {
      cursor: help;
    }
  }
  &__uri-logo {
    height: 123px;
    margin-right: 10px;

    @media (max-width: 435px) {
      margin: 0 0 10px;
    }
  }
  &__edu {
    flex-grow: 1;

    &-inline-block {
      display: inline-block;
    }
    @media (min-width: 575px) {
      .home-page__dates {
        margin-top: -24px;
      }
    }
  }
}
</style>
