<template>
  <div class="profile-images" aria-hidden="true">
    <div class="profile-images__slide" v-for="(image, index) in images" v-show="showImage === index" :key="index">
      <img class="profile-images__base" :src="getImage(image)" />
    </div>
  </div>
</template>

<script>
import { PowerGlitch } from "powerglitch";

export default {
  name: "ProfileImages",
  data() {
    return {
      timeEach: 5000,
      timeGlitch: 650,
      images: ["cyborg", "casual", "formal", "funky"],
      showImage: 1
    };
  },
  mounted() {
    this.glitchLoop();
  },
  methods: {
    getImage: name => require(`@/assets/profile/${name}.png`),
    glitchLoop: function() {
      const options = { playMode: "manual", glitchTimeSpan: false, shake: { amplitudeX: 0.1, amplitudeY: 0.1 } };
      const { startGlitch, stopGlitch } = PowerGlitch.glitch(".profile-images__base", options);

      setInterval(() => {
        startGlitch();
        setTimeout(() => (this.showImage = ++this.showImage % this.images.length), this.timeGlitch / 2);
        setTimeout(() => stopGlitch(), this.timeGlitch);
      }, this.timeEach);
    }
  }
};
</script>

<style lang="scss">
.profile-images {
  position: relative;
  overflow: hidden;

  &__base {
    max-width: 100%;
  }
}
</style>
