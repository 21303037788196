<template>
  <a name="projects" class="home-page__anchor" />
  <div class="home-page__projects">
    <div class="home-page__projects-tab-container">
      <button class="home-page__projects-tab" @click="showTab = i" v-for="(section, i) in sections" :key="i">
        <h2 :class="`home-page__sub-header ${i === showTab ? 'home-page__sub-header--active' : ''}`">
          {{ section.title }}
        </h2>
      </button>
    </div>
    <div class="home-page__projects-section">
      <div class="home-page__text-block">{{ sections[showTab].blurb }}</div>
      <div class="home-page__text-block" v-for="(project, j) in sections[showTab].projects" :key="j">
        <span class="home-page__projects-link-container" v-if="project.iconLinks">
          <a
            class="home-page__projects-link"
            :href="link.href"
            :target="link.target"
            :title="link.title"
            @click="link.blogPost ? goToPost(link.blogPost) : () => {}"
            v-for="(link, l) in generateIconLinks(...project.iconLinks)"
            :key="l"
          >
            <fa class="home-page__projects-link-icon" :icon="link.icon" :type="link.type" stroke="20"></fa>
          </a>
        </span>
        <h3 class="home-page__inline-header">{{ project.title }}</h3>
        <em>{{ project.blurb }}</em>
        <ul class="home-page__list">
          <li v-for="(point, k) in project.bulletPoints" :key="k" v-html="parseLinks(point)"></li>
        </ul>
        <div :class="`screenshots${project.isWide ? ' gallery-wide' : ''}`" v-if="project.images || project.videos">
          <img v-bind="galleryProps(video, project.imageDir, true)" v-for="(video, s) in project.videos" :key="s" />
          <img v-bind="galleryProps(image, project.imageDir)" v-for="(image, s) in project.images" :key="s" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import projectData from "../json/projects.json";

export default {
  name: "Projects",
  props: ["goToPost"],
  data() {
    return {
      showTab: 0,
      sections: projectData
    };
  },
  methods: {
    parseLinks: s =>
      s.replace(/(\[([^\]]+)])\(([^)]+)\)/g, '<a class="home-page__inline-link" target="_blank" href="$3">$2</a>'),
    galleryProps: (slide, dir, isVideo) => {
      const getUrl = s => {
        if (s.includes("http")) return s;
        return require(`@/assets/screenshots/${dir ? dir + "/" : ""}${s}${s.includes(".") ? "" : ".jpg"}`);
      };
      const content = isVideo ? { video: slide.src } : { full: getUrl(slide.src) };
      return { src: getUrl(slide.thumb), alt: slide.alt, ...content };
    },
    generateIconLinks: (link, blog, download) => {
      const generateFields = (name, href) => ({
        icon: name === "blog" ? "comment-alt" : name,
        type: name === "blog" ? "far" : "fas",
        ...(name === "blog" ? { blogPost: href, href: "#" } : { href }),
        target: href.includes("http") && !href.includes("self") ? "_blank" : "_self",
        title: { link: "Visit website", blog: "Go to blog post", download: "Download project" }[name]
      });
      return [
        ...(link ? [generateFields("link", link)] : []),
        ...(blog ? [generateFields("blog", blog)] : []),
        ...(download ? [generateFields("download", download)] : [])
      ];
    }
  }
};
</script>

<style lang="scss">
@import "../mixins/glow.scss";
@import "../mixins/responsive.scss";

.home-page__projects {
  @include responsiveWidth;

  &-tab-container {
    display: flex;
    justify-content: space-around;
    gap: 15px;
    margin: 20px 0 5px;

    @media (max-width: 435px) {
      flex-direction: column;
    }
  }
  &-tab {
    background: transparent;
    border: 0;
    margin: 0 auto;

    .home-page__sub-header {
      text-align: center;
      margin: 0;

      &:not(&--active) {
        cursor: pointer;
        color: #dbe8f4;
        font-size: 24px;
        @include neonText(0.5, #2289e9);
      }

      @media (max-width: 765px) and (min-width: 436px) {
        max-width: 160px;
        &:not(&--active) {
          max-width: 130px;
        }
      }
    }
  }

  .home-page__text-block {
    margin: 10px 0;
  }

  &-link-container {
    float: right;
  }
  &-link {
    margin: 5px;

    &-icon {
      @include neonIcon(#2289e9);
    }
  }

  .screenshots:not(.gallery-wide) {
    max-width: 800px;
  }
}
</style>
