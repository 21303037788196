<template>
  <div class="home-page">
    <a name="top" />
    <TopNav :toggleBlog="toggleBlog" :sortOrderNew="sortOrderNew" :goToPost="slug" />

    <div :class="`home-page__content ${hideContent ? 'home-page__content_hide-left' : ''}`" v-show="!showBlog">
      <div class="home-page__above-fold">
        <div class="home-page__above-fold-left-col">
          <ProfileImages />

          <div class="home-page__nametag">
            <h1 class="home-page__nametag-name">Zachary Stiles</h1>
            <p class="home-page__nametag-title">
              Front-End Developer<br />
              UI/UX Engineer<br />
              Aspiring Cyborg
            </p>
          </div>
        </div>

        <div class="home-page__above-fold-right-col">
          <InfoBlocks />
          <ContactLinks />
        </div>
      </div>

      <WorkHistory />

      <Projects :goToPost="goToPost" />

      <a class="home-page__return-link" href="#top" title="Return to top">
        <fa class="home-page__return-link-icon" icon="chevron-circle-up" stroke="20"></fa>
      </a>
    </div>

    <div :class="`home-page__content ${hideContent ? 'home-page__content_hide-right' : ''}`" v-show="showBlog">
      <Blog :sortOrderNew="sortNewFirst" />

      <a class="home-page__return-link" href="#top" title="Return to top">
        <fa class="home-page__return-link-icon" icon="chevron-circle-up" stroke="20"></fa>
      </a>
    </div>

    <ContactLinks mini="true" :showBlog="showBlog" />
    <Modal />
  </div>
</template>

<script>
import TopNav from "./TopNav";
import ProfileImages from "./ProfileImages";
import InfoBlocks from "./InfoBlocks";
import ContactLinks from "./ContactLinks";
import WorkHistory from "./WorkHistory";
import Projects from "./Projects";
import Blog from "./Blog";
import Modal from "./Modal";

export default {
  name: "HomePage",
  components: {
    TopNav,
    ProfileImages,
    InfoBlocks,
    ContactLinks,
    WorkHistory,
    Projects,
    Blog,
    Modal
  },
  data() {
    return {
      hideContent: false,
      showBlog: window.location.hash.startsWith("#/blog"),
      sortNewFirst: true,
      slug: ""
    };
  },
  methods: {
    toggleBlog: function() {
      this.hideContent = true;
      setTimeout(() => {
        this.showBlog = !this.showBlog;
        setTimeout(() => {
          window.scrollTo(0, 0);
          this.hideContent = false;
        }, 10);
      }, 250);
    },
    sortOrderNew: function(sortNewFirst) {
      this.sortNewFirst = sortNewFirst;
    },
    goToPost: function(slug) {
      this.slug = slug;
    }
  }
};
</script>

<style lang="scss">
@import "../mixins/glow.scss";

.home-page {
  padding-bottom: 150px;

  &__top-nav {
    position: fixed;
    top: 0px;
    left: 0px;
  }
  &__anchor {
    position: relative;
    top: -45px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    transition: margin 1s;
    overflow: hidden;
    &_hide-right {
      margin-left: 200vw;
    }
    &_hide-left {
      margin-right: 200vw;
    }
  }

  &__above-fold {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 1300px;

    @media (max-width: 1600px) {
      width: 1200px;
    }
    @media (max-width: 1250px) {
      width: 1150px;
    }
    @media (max-width: 1175px) {
      flex-direction: column;
      margin: 75px 0 0;
      height: auto;
      width: calc(100% - 10px);
    }
    @media (max-height: 890px) {
      margin-top: 75px;
      height: auto;
    }

    &-left-col {
      width: 500px;
      max-width: 100%;
    }
    &-right-col {
      width: 600px;
      max-width: 100%;
    }
  }

  $headerColor: #ff4da6;

  &__nametag {
    &-name {
      margin: 0;
      font-family: "Audiowide", sans-serif;
      font-weight: initial;
      font-size: 55px;
      @include glow(5, $headerColor);
    }
    &-title {
      margin: 5px 0;
      padding: 5px 0;
      font-size: 24px;
      font-family: "Audiowide", sans-serif;
      color: $headerColor;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  &__sub-header {
    margin: 20px 0 5px;
    font-family: "Audiowide", sans-serif;
    font-size: 30px;
    text-align: left;
    @include glow(2, $headerColor);

    @media (max-width: 634px) {
      text-align: center;
    }
  }
  &__inline-header {
    margin: 10px 0 0;
    font-family: "Audiowide", sans-serif;
    color: $headerColor;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__text-block {
    padding: 10px;
    font-size: 20px;
    text-align: left;
    color: #b1d4f5;
    background: rgba(0, 0, 0, 0.5);
  }
  &__list {
    margin: 0;
    padding-left: 20px;
    list-style-type: circle;
  }
  &__dates {
    text-align: right;
  }

  &__inline-link {
    @include neonText(0.2, #2289e9);
  }

  &__return-link {
    margin-top: 20px;

    &-icon {
      @include neonIcon($headerColor);
    }
  }
}

.screenshots {
  margin: 15px auto 0;
  text-align: center;

  img {
    height: 120px;
    margin: 5px;
    border: double #7fbbf2 4px;
    border-radius: 5px;
    padding: 2px;
    cursor: pointer;

    &:hover {
      position: relative;
      top: -5px;
      border: double #ff4da6 4px;
      @include boxGlow(1, #ff4da6);
    }
  }
}
</style>
