<template>
  <a name="work" class="home-page__anchor" />
  <div class="home-page__work">
    <h2 class="home-page__sub-header">Work History</h2>
    <div class="home-page__work-block" v-for="(job, index) in jobs" :key="index">
      <img class="home-page__work-image" :src="getImage(job.logo)" />
      <div class="home-page__text-block">
        <h3 class="home-page__inline-header">{{ job.company }}</h3>
        <em>{{ job.title }}</em>
        <ul class="home-page__list">
          <li v-for="(point, index) in job.bulletPoints" :key="index">{{ point }}</li>
        </ul>
        <div class="home-page__dates">{{ job.tenure }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import workData from "../json/work.json";

export default {
  name: "WorkHistory",
  data() {
    return {
      jobs: workData
    };
  },
  methods: {
    getImage: name => require(`@/assets/logos/${name}`)
  }
};
</script>

<style lang="scss">
@import "../mixins/responsive.scss";

.home-page__work {
  @include responsiveWidth;

  &-block {
    display: flex;
    margin: 10px 0;

    @media (max-width: 1175px) {
      width: calc(100% - 10px);
    }
    @media (max-width: 800px) {
      flex-direction: column;
    }
  }
  &-image {
    object-fit: contain;
    width: 250px;
    margin: 0 25px;

    @media (max-width: 800px) {
      margin: 10px auto 25px;
    }
  }
  .home-page__text-block {
    flex-grow: 1;
  }
}
</style>
