<template>
  <div class="top-nav home-page__top-nav">
    <div class="top-nav__left" v-show="!showBlog">
      <a class="top-nav__link" href="#about">About</a>
      <a class="top-nav__link" href="#work">Work</a>
    </div>
    <div class="top-nav__left top-nav__left--blog" v-show="showBlog">
      <button class="top-nav__link" @click="clickBlogLink">&#9664; Back</button>
    </div>

    <a href="#top">
      <img class="top-nav__logo" src="../assets/logos/zack_logo.png" alt="retro sunset logo, initialed ZAS" />
    </a>

    <div class="top-nav__right" v-show="!showBlog">
      <a class="top-nav__link" href="#projects">Projects</a>
      <button class="top-nav__link" @click="clickBlogLink">Blog</button>
    </div>
    <div class="top-nav__right top-nav__right--blog" v-show="showBlog">
      <a class="top-nav__link" href="#top">{{ returnTopLabel }}</a>
      <button class="top-nav__link" @click="sortOrder">{{ sortOrderLabel }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopNav",
  props: ["toggleBlog", "sortOrderNew", "goToPost"],
  data() {
    return {
      showBlog: window.location.hash.startsWith("#/blog"),
      sortNewFirst: "newest",
      sortOrderLabel: "▼ Newest First",
      returnTopLabel: "Return to Top",
      windowWidth: window.innerWidth
    };
  },
  watch: {
    goToPost(slug) {
      this.clickBlogLink(null, slug);
      this.sortOrder(null, false);
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();

    if (window.location.hash.startsWith("#/blog#")) this.scrollToPost();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    clickBlogLink: function(_, slug) {
      this.showBlog = !this.showBlog;
      this.toggleBlog();

      window.history.pushState({}, "", this.showBlog ? `#/blog` : "/");
      this.scrollToPost(slug);
    },
    scrollToPost: function(slug) {
      const hash = slug ? `#${slug}` : window.location.hash.replace("#/blog", "");
      if (this.showBlog && hash) {
        setTimeout(() => (window.location.hash = hash), 1000);
        setTimeout(() => (window.location.hash = `#/blog${hash}`), 1250);
      }
    },
    onResize: function() {
      this.windowWidth = window.innerWidth;
      this.sortOrderLabel = this.sortNewFirst ? "▼ Newest First" : "▲ Oldest First";
      if (this.windowWidth <= 520) {
        this.returnTopLabel = "Top";
        this.sortOrderLabel = this.sortOrderLabel.replace(" First", "");
      } else {
        this.returnTopLabel = "Return to Top";
      }
    },
    sortOrder: function(_, newVal) {
      this.sortNewFirst = newVal !== undefined ? newVal : !this.sortNewFirst;
      this.sortOrderNew(this.sortNewFirst);
      this.onResize();
    }
  }
};
</script>

<style lang="scss">
.top-nav {
  height: 35px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  z-index: 9001;
  background: rgb(12, 0, 255);
  background: linear-gradient(170deg, rgba(12, 0, 255, 0.7) 0%, rgba(255, 0, 232, 0.7) 100%);

  &__left {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(50% - 28px);
    text-align: right;

    &--blog {
      text-align: center;
      button.top-nav__link {
        @media (max-width: 460px) {
          font-size: 15px;
          white-space: nowrap;
        }
      }
    }
  }
  &__right {
    position: fixed;
    top: 0;
    left: calc(50% + 28px);
    width: calc(50% - 28px);
    text-align: left;

    &--blog {
      text-align: center;
      .top-nav__link {
        width: initial;
        font-size: 14px;
        padding: 0 8px;

        @media (max-width: 520px) {
          font-size: 13px;
        }
      }
      button.top-nav__link {
        width: 115px;
        text-align: left;

        @media (max-width: 520px) {
          width: 78px;
        }
      }
    }
  }

  &__logo {
    margin: 5px 5px -27.5px;
  }
  &__link {
    display: inline-block;
    height: 35px;
    width: 100px;
    line-height: 35px;
    text-decoration: none;
    text-align: center;
    color: #ffffff;

    @media (max-width: 455px) {
      width: 62px;
    }

    @at-root button#{&} {
      background: transparent;
      border: 0;
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      cursor: pointer;
    }

    &:hover {
      background: #ffffff !important;
      color: #000000;
    }
  }
}
</style>
