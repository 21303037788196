<template>
  <div class="modal" v-if="showModal">
    <div class="modal__backdrop" @click="closeModal"></div>
    <div class="modal__content">
      <template v-if="content[index].video">
        <iframe class="modal__content-video" :src="videoUrl(content[index].video)" allowfullscreen></iframe>
      </template>
      <template v-else>
        <img class="modal__content-image" :src="content[index].src" @click="showZoom = true" v-if="!showZoom" />
        <img
          class="modal__content-image-zoom"
          :src="content[index].src"
          @click="showZoom = false"
          @mousemove="panImg"
          v-else
        />
      </template>
      <div class="modal__content-caption home-page__text-block" v-if="content[index].alt">{{ content[index].alt }}</div>
    </div>
    <button class="modal__prev" @click="goTo(-1)" v-if="content.length > 1">
      <fa class="modal__prev-icon" icon="arrow-circle-left" type="fas" stroke="20"></fa>
    </button>
    <button class="modal__next" @click="goTo(1)" v-if="content.length > 1">
      <fa class="modal__next-icon" icon="arrow-circle-right" type="fas" stroke="20"></fa>
    </button>
    <button class="modal__close" @click="closeModal">
      <fa class="modal__close-icon" icon="times-circle" type="far" stroke="20"></fa>
    </button>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      showModal: false,
      showZoom: false,
      content: [],
      index: 0
    };
  },
  mounted() {
    document.body.addEventListener("click", this.clickEvent);
    document.body.addEventListener("keydown", this.keyEvent);
  },
  beforeUnmount() {
    document.body.removeEventListener("click", this.clickEvent);
    document.body.removeEventListener("keydown", this.keyEvent);
  },
  methods: {
    clickEvent: function({ target: t }) {
      // Screenshot image gallery.
      if (t.closest(".screenshots") && t.tagName === "IMG") this.openModal(t);

      // TopNav clicked.
      if (t.closest(".top-nav") && this.showModal) this.closeModal();
    },
    keyEvent: function({ key }) {
      if (this.showModal) {
        if (key === "ArrowLeft") this.goTo(-1);
        if (key === "ArrowRight") this.goTo(1);
        if (key === "Escape") this.closeModal();
      }
    },
    videoUrl: s => `https://www.youtube.com/embed/${s}?showinfo=0&modestbranding=1&rel=0`,
    toggleScroll: modalOpen => {
      if (modalOpen) {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        const halfNavW = `calc(50% - ${28 + scrollbarWidth / 2}px)`;
        const halfNavL = `calc(50% + ${28 - scrollbarWidth / 2}px)`;

        document.head.innerHTML += `<style id="disableScrolling">
          body { overflow: hidden; padding-right: ${scrollbarWidth}px; }
          .top-nav > a { margin-left: -${scrollbarWidth}px; }
          .top-nav__left { width: ${halfNavW}; }
          .top-nav__right { width: ${halfNavW}; left: ${halfNavL}; }
          .home-page__contact_follow { margin-right: ${scrollbarWidth}px; }
        </style>`;
      } else document.head.removeChild(document.querySelector("#disableScrolling"));
    },
    openModal: function(t) {
      this.toggleScroll(true);
      const gallery = [...t.parentElement.querySelectorAll("img")];
      this.content = gallery.map(i => ({
        video: i.getAttribute("video"),
        src: i.getAttribute("full") || i.src,
        alt: i.alt
      }));
      this.index = gallery.indexOf(t);
      this.showModal = true;
    },
    panImg: e => {
      const rect = e.target.getBoundingClientRect();
      const x = (e.clientX - rect.left) / rect.width;
      const y = (e.clientY - rect.top) / rect.height;
      e.target.style.objectPosition = `${x * 120 - 10}% ${y * 140 - 20}%`;
    },
    goTo: function(n) {
      this.showZoom = false;
      this.index += n;
      if (this.index < 0) this.index = this.content.length - 1;
      if (this.index >= this.content.length) this.index = 0;
    },
    closeModal: function() {
      this.toggleScroll();
      this.showModal = false;
      this.showZoom = false;
      this.content = "";
    }
  }
};
</script>

<style lang="scss">
@import "../mixins/glow.scss";

.modal {
  position: fixed;
  top: 0;
  z-index: 8999;

  &__backdrop {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.77);
    z-index: -1;
  }
  &__content {
    position: fixed;
    top: 42px;
    left: 55px;
    height: calc(100% - 49px);
    width: calc(100% - 110px);
    text-align: center;
    overflow: hidden;

    &-video,
    &-image {
      position: relative;
      top: 50%;
      max-width: 100%;
      transform: translateY(-50%);
    }
    &-video {
      aspect-ratio: 16/9;
      width: 100%;
      max-height: 80%;
      border: 0;
    }
    &-image {
      max-height: 100%;
      cursor: zoom-in;
      &-zoom {
        transform-origin: center center;
        object-fit: cover;
        height: 100%;
        width: 100%;
        cursor: zoom-out;
      }
    }
    & &-caption {
      position: fixed;
      bottom: 40px;
      left: -10px;
      width: 100%;
      text-align: center;
      pointer-events: none;
    }
  }
  &__close,
  &__prev,
  &__next {
    position: fixed;
    background: transparent;
    border: 0;
    cursor: pointer;

    &-icon {
      @include neonIcon(#ff4da6);
    }
  }
  &__close {
    top: 40px;
    right: 5px;
  }
  &__prev {
    top: 50vh;
    left: 5px;
  }
  &__next {
    top: 50vh;
    right: 5px;
  }

  @media (max-width: 520px) {
    z-index: 9000;

    &__content {
      top: 35px;
      left: 0;
      height: calc(100% - 35px);
      width: 100%;
    }
    &__prev,
    &__next {
      top: initial;
      bottom: 5px;
    }
  }
}
</style>
