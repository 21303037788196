<template>
  <div class="blog">
    <h2 class="home-page__sub-header">
      {{ this.cat ? `Category: ${this.cat}` : "Dev Blog (2016)" }}
      <button class="blog__remove-filter" @click="this.cat = ''" v-if="this.cat">
        <fa class="blog__remove-filter-icon" icon="times-circle" type="far" stroke="20"></fa>
      </button>
    </h2>
    <div
      class="home-page__text-block home-page__text-block--glow"
      @click="expandPost"
      @keydown="expandPost"
      tabIndex="0"
      v-for="(post, index) in this.filterCat(blogPosts)"
      :key="index"
    >
      <a class="blog__post-link" :name="post.slug" />
      <h3 class="home-page__inline-header">{{ post.title }}</h3>
      {{ formatTime(post.published) }}
      <img class="blog__featured-image" :src="post.featured_image" />
      <div class="blog__post-body">{{ post.summary }}</div>
      <a class="blog__tag" href="#" @click="this.cat = cat.name" v-for="(cat, index) in post.categories" :key="index">
        {{ cat.name }}
      </a>
      <div class="blog__expand-label">(Click to expand)</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Blog",
  props: ["sortOrderNew"],
  data() {
    return {
      blogPosts: [],
      loadedPosts: [],
      cat: ""
    };
  },
  watch: {
    sortOrderNew() {
      this.blogPosts = this.blogPosts.reverse();
    }
  },
  mounted() {
    this.butter(null, ["page_size=9999", "exclude_body=true"], posts => (this.blogPosts = posts));
  },
  methods: {
    butter: (slug, params, callback) => {
      const base = "https://api.buttercms.com/v2/posts/";
      const tok = "auth_token=68b6a8280a37a14a8a63bd8e4b46af54cd9e2ff7";
      axios.get(`${base}${slug ? slug + "/" : ""}?${tok}&${params.join("&")}`).then(r => callback(r.data.data));
    },
    formatTime: time => {
      const d = new Date(time);

      const dayName = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"][d.getDay()];
      const month = [
        ...["January", "February", "March", "April", "May", "June"],
        ...["July", "August", "September", "October", "November", "December"]
      ][d.getMonth()];

      const dayNum = d.getUTCDate();
      const y = d.getFullYear();
      const h = d.getUTCHours() % 12 || 12;
      const a = d.getUTCHours() >= 12 ? "pm" : "am";
      let m = d.getMinutes();
      if (m < 10) m = `0${m}`;

      return `posted ${dayName}, ${month} ${dayNum} ${y} @ ${h}:${m} ${a} EST`;
    },
    expandPost: function(e) {
      if (e.target.className === "blog__tag") return;
      if (e.type === "keydown" && ![" ", "Space", "Enter"].includes(e.key)) return;
      else if ([" ", "Space"].includes(e.key)) e.preventDefault();

      const post = e.target.closest(".home-page__text-block");
      const slug = post.querySelector(".blog__post-link").getAttribute("name");
      if (this.loadedPosts.includes(slug)) return;

      this.butter(slug, [], data => {
        post.querySelector(".blog__post-body").innerHTML = data.body;
        post.querySelector(".blog__expand-label").remove();
        post.classList.remove("home-page__text-block--glow");
        this.loadedPosts.push(slug);
      });
    },
    filterCat: function(posts) {
      if (!this.cat) return posts;
      return posts.filter(post => post.categories.filter(cat => cat.name === this.cat).length);
    }
  }
};
</script>

<style lang="scss">
@import "../mixins/glow.scss";
@import "../mixins/responsive.scss";

.blog {
  margin-top: 75px;
  @include responsiveWidth;

  a {
    @include neonText(0.2, #2289e9);
  }
  p {
    margin: 10px 0;
  }
  table {
    margin: 10px;
    border: double #ff4da6 4px;
    border-collapse: collapse;
    @include boxGlow(1, #ff4da6);

    tr:nth-child(even) {
      background: rgba(255, 77, 166, 0.65);
    }
    td {
      padding: 0 7px;
    }
  }

  &__post-link {
    position: relative;
    top: -35px;
  }
  &__featured-image {
    display: block;
    max-width: 100%;
    margin: 10px auto 0;
  }
  &__tag {
    display: inline-block;
    margin-right: 7px;
  }
  &__expand-label {
    font-weight: bold;
    float: right;
  }
  &__remove-filter {
    position: relative;
    top: 9px;
    margin: -12px 0;
    border: 0;
    background: transparent;
    cursor: pointer;

    &-icon {
      @include neonIcon(#ff4da6);
    }
  }

  .home-page__sub-header,
  .home-page__text-block {
    width: 900px;
    max-width: calc(100% - 20px);
    margin: 0 auto 30px;
  }
  .home-page__text-block--glow {
    cursor: pointer;

    &:hover,
    &:focus {
      @include boxGlow(1, #ff4da6);
    }
    .blog__post-body {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin: 10px 0;
    }
  }
}
</style>
