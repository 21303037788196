<template>
  <div class="home-page__contact" v-if="desktopView && !mini">
    <a
      class="home-page__contact-link"
      target="_blank"
      :href="link.href"
      :title="link.title"
      v-for="(link, index) in bigLinks"
      :key="index"
    >
      <fa
        :class="`home-page__contact-icon home-page__contact-icon_${link.name}`"
        :icon="link.icon"
        :type="link.type"
        stroke="20"
      ></fa>
    </a>
    <div class="home-page__contact-text-block">
      <a
        class="home-page__contact-link home-page__contact-link_small"
        target="_blank"
        :href="link.href"
        :title="link.title"
        v-for="(link, index) in smallLinks"
        :key="index"
      >
        <fa
          :class="`home-page__contact-icon home-page__contact-icon_small home-page__contact-icon_${link.name}`"
          :icon="link.icon"
          :type="link.type"
          stroke="20"
        ></fa>
        <div class="home-page__contact-link-label">{{ link.text }}</div>
      </a>
    </div>
  </div>
  <div
    :class="`home-page__contact home-page__contact_follow ${linksFollow ? '' : 'home-page__contact_hide'}`"
    v-if="mini"
  >
    <a
      class="home-page__contact-link home-page__contact-link_follow"
      target="_blank"
      :href="link.href"
      :title="link.title"
      v-for="(link, index) in bigLinks.concat(smallLinks)"
      :key="index"
    >
      <fa
        :class="`home-page__contact-icon home-page__contact-icon_follow home-page__contact-icon_${link.name}`"
        :icon="link.icon"
        :type="link.type"
        stroke="20"
      ></fa>
    </a>
  </div>
</template>

<script>
export default {
  name: "ContactLinks",
  props: ["mini", "showBlog"],
  data() {
    return {
      debounce: false,
      desktopView: true,
      linksFollow: false,
      bigLinks: [
        {
          name: "resume",
          icon: "file-invoice",
          type: "fas",
          href: "/ZStiles_Resume.pdf",
          title: "Resume"
        },
        {
          name: "linkedin",
          icon: "linkedin-in",
          type: "fab",
          href: "https://www.linkedin.com/in/zacharystiles",
          title: "LinkedIn"
        },
        {
          name: "facebook",
          icon: "facebook-f",
          type: "fab",
          href: "https://www.facebook.com/stiles.zachary",
          title: "Facebook"
        },
        {
          name: "youtube",
          icon: "youtube",
          type: "fab",
          href: "https://youtube.com/ZacharyStiles",
          title: "YouTube"
        }
      ],
      smallLinks: [
        {
          name: "phone",
          icon: "phone-alt",
          type: "fas",
          href: "tel:+14012255673",
          title: "Call me!",
          text: "\xa0(401) 225-5673"
        },
        {
          name: "email",
          icon: "envelope",
          type: "far",
          href: "mailto:zack@zacharystiles.tech",
          title: "Email me!",
          text: "zack@zachary\nstiles.tech"
        }
      ]
    };
  },
  watch: {
    showBlog() {
      if (this.mini) this.onScroll();
    }
  },
  mounted() {
    if (this.mini) {
      window.addEventListener("scroll", this.onScroll);
      this.onScroll();
    } else {
      window.addEventListener("resize", this.onResize);
      this.onResize();
    }
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onResize: function() {
      this.desktopView = window.innerWidth >= 530;
    },
    onScroll: function() {
      if (!this.debounce) {
        this.debounce = true;
        setTimeout(() => {
          this.debounce = false;
          const nametag = document.querySelector(".home-page__nametag");
          this.linksFollow = this.showBlog ? true : nametag.getBoundingClientRect().bottom <= 0;
        }, 250);
      }
    }
  }
};
</script>

<style lang="scss">
@import "../mixins/glow.scss";

.home-page__contact {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  &_follow {
    position: fixed;
    height: 50px;
    width: 300px;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: bottom 0.5s;
    z-index: 9000;
  }
  &_hide {
    bottom: -50px;
  }

  &-link {
    display: inline-block;
    height: 75px;
    width: 75px;
    margin: 0 5px;

    &_small {
      display: flex;
      height: 35px;
      width: 100%;
      margin: 0;
      align-items: center;
      text-decoration: none;
    }
    &-label {
      position: relative;
      display: flex;
      align-items: center;
      top: -3px;
      left: 10px;
      height: 35px;
      width: 134px;
      line-height: 20px;
      font-size: 18px;
      color: #b1d4f5;
      background: rgba(0, 0, 0, 0.5);

      .home-page__contact-icon_email + & {
        padding: 5px 0;
      }
      a:focus > &,
      a:hover > & {
        @include glow(1, #679dcf);
      }
    }

    &_follow {
      position: relative;
      top: 5px;
      left: -3px;
      height: 35px;
    }
  }
  &-icon {
    height: 75px;
    width: 75px;

    &_small {
      height: 35px;
      width: 35px;
    }
    &_follow {
      height: 40px;
      width: 40px;
    }
  }
  &-text-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 5px;
    width: 180px;
  }

  &-icon_resume,
  &-icon_phone {
    @include neon(1, #26c626);
  }
  &-icon_linkedin {
    @include neon(1, #4ea4f2);
  }
  &-icon_facebook {
    @include neon(1, #2289e9);
  }
  &-icon_youtube {
    @include neon(1, #df3c3c);
  }
  &-icon_email {
    @include neon(1, #aab511);
    position: relative;
    left: 2px;
  }
}
</style>
